import { Store } from '@actr/stores';
import railLog from 'Logs/rail/base';

export enum RailErLogs {
  CLICK_OPEN_MODAL, // клик по кнопке открытия модалки
  OPEN_MODAL, // открытие модалки
  CLOSE_MODAL, // закрытие модалки

  TRY_GET_TICKET_INFO, // отправка запроса на получение билетов
  /** @param pid
   * @param erChangeAvailable - разрешено изменение ER
   */
  GET_TICKET_INFO_SUCCESS, // запрос на получение билетов успешен
  /** @param pid */
  GET_TICKET_INFO_FAILED, // запрос на получение билетов завершился ошибкой

  SHOW_NOT_POSSIBLE_INFO, // показ экрана с инфой о невозможности возврата
  SHOW_SUCCESS_INFO, // показ экрана с инфой об успешности возврата
  SHOW_PARTIALLY_SUCCESS_INFO, // показ экрана с инфой о частичном успехе возврата
  /** @param segmentsAvailableToChangeEr - количество сегментов, для которых можно менять ER
   * @param erChangeAvailable - доступно ли изменение ER
   */
  SHOW_SELECTION_INFO, // показ экрана с выбором билетов к возврату
  /** @param index - индекс сегмента, начиная с 0
   * @param erChangeAvailable - разрешено изменение ER
   */
  SELECTION_INFO_SELECT_SEGMENT, // выбран сегмент для просмотра
  /** @param changeAllowed - разрешено делать изменение */
  CLICK_CHANGE_BTN, // клик по кнопке изменения ER
  TRY_CHANGE_ER, // запросы на изменение ER
  CHANGE_ER_SUCCESS, // запросы на изменение ER все завершились успешно
  CHANGE_ER_FAILED, // запросы на изменение ER все завершились ошибкой
  /** @param successPid - пиды успешных запросов
   *  @param errorsPid - пиды запросов c ошибкой
   */
  CHANGE_ER_PARTIALLY_SUCCESS // запросы на изменение ER частично завершились успешно
}

const railErLog = railLog.extend('er').bindEnum(RailErLogs);

export function initRailErLogs(store: Store) {
  railErLog.addData(() => ({
    billingNumber: store.railErChange.order?.billing_number
  }));
}

export default railErLog;
