import { useInject } from '@actr/di';
import { AccountAviaAexRefundManager, AccountAviaRefundManager, HotelRefundStore, RailRefundStore, Store } from '@actr/stores';
import AccountAviaPassengersEditingManagerStore from '@actr/stores/src/stores/stores/account/avia/passengers-editing/manager';
import AccountAviaUnsuccessfulTicketingManager
  from '@actr/stores/src/stores/stores/account/avia/unsuccessful-ticketing';
import {
  initAviaAexRefundLogs,
  initAviaExchangeLogs,
  initAviaInsuranceRefundLogs,
  initAviaOrderOptionsLogs,
  initAviaRefundLogs,
  initUnsuccessfulTicketingRefundLogs
} from '@actr/widgets';
import { initAccountAviaPassengersEditingLogs } from '@actr/widgets/src/logs/avia/passengers-editing';
import { initAppLogs } from 'Logs/app';
import { initHotelRefundLogs } from 'Logs/hotel/refund';
import { initRailErLogs } from 'Logs/rail/er';
import { initRailRefundLogs } from 'Logs/rail/refund';
import { useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { initAviaPaymentLogs } from 'Root/logs/avia/payment';

/** Хук для инициализации логов */
const UseInitLogging = () => {
  const store = useInstance(Store);
  const hotelRefundStore = useInject(HotelRefundStore);
  const refundAexManager = useInject(AccountAviaAexRefundManager);
  const refundManager = useInject(AccountAviaRefundManager);
  const railRefundStore = useInject(RailRefundStore);
  const accountAviaUnsuccessfulTicketingManager = useInject(AccountAviaUnsuccessfulTicketingManager);
  const accountAviaPassengersEditingManagerStore = useInject(AccountAviaPassengersEditingManagerStore);

  useEffect(() => {
    initAppLogs(store);
    initAviaRefundLogs(store, refundManager);
    initUnsuccessfulTicketingRefundLogs(accountAviaUnsuccessfulTicketingManager);
    initAviaOrderOptionsLogs(store);
    initAviaPaymentLogs();
    initAviaAexRefundLogs(store, refundAexManager);
    initAviaInsuranceRefundLogs(store);
    initHotelRefundLogs(hotelRefundStore);
    initRailRefundLogs(railRefundStore);
    initRailErLogs(store);
    initAviaExchangeLogs(store);
    initAccountAviaPassengersEditingLogs(accountAviaPassengersEditingManagerStore);
  }, []);
};

export default UseInitLogging;
