import { set } from '@actr/config';

import production from './production';
import type { LocalConfig } from './specs';

const productionConfig: LocalConfig = production();

set(productionConfig);

export default productionConfig;
export {
  LocalConfig
};
