import { defineDIModule } from '@actr/di/src/simple';
import { SingleOrderAuthService } from 'Services/services/single-order/auth';
import SingleOrderLoginStore from 'Store/single-order/login';

const singleOrderDIModule = defineDIModule([
  SingleOrderLoginStore,
  SingleOrderAuthService
]);

export default singleOrderDIModule;
