import { useNavigation } from '@actr/hooks';
import { IconPayedOrder } from '@actr/icons/payed-order';
import { IconProfile } from '@actr/icons/profile';
import { IconSearch } from '@actr/icons/search';
import { IconSettings } from '@actr/icons/settings';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import useCommonTranslator from 'Root/locales';
import WebviewRoutes from 'Router/webview/routes';

import * as Styled from './style';

export type ToolBarLink = {
    label: ReactNode;
    icon: ReactNode;
    route: string;
    useRouter?: boolean;
}
export interface ToolBarProps {}
const ToolBar: FC<ToolBarProps> = () => {
  const { isRoute } = useNavigation();

  const { t, locale } = useCommonTranslator();

  const routes: ToolBarLink[] = useMemo(() => [
    { label: t('Поиск'), route: WebviewRoutes.AVIA_SEARCH, useRouter: false, icon: <IconSearch /> },
    { label: t('Заказы'), route: WebviewRoutes.ORDERS, icon: <IconPayedOrder /> },
    { label: t('Пассажиры'), route: WebviewRoutes.PASSENGERS, icon: <IconProfile /> },
    { label: t('Профиль'), route: WebviewRoutes.PROFILE, icon: <IconSettings /> },
  ], [locale]);

  const { ref } = useResizeDetector<HTMLDivElement>({
    handleWidth: false,
  });

  return (
    <div style={{ height: ref.current?.clientHeight || 0 }}>
      <Styled.Wrapper ref={ref}>
        {routes.map(route => (
          <Styled.Link
            font="title.h7"
            route={route.route}
            isActive={isRoute(route.route)}
            useRouter={route.useRouter ?? true}>
            <Styled.IconWrapper>
              {route.icon}
            </Styled.IconWrapper>
            <span>
              {route.label}
            </span>
          </Styled.Link>
        ))}
      </Styled.Wrapper>
    </div>
  );
};

export default observer(ToolBar);
