import { prepareRoutesPaths } from '@actr/utilities';
import { AccountRoutes, SiteLoader } from '@actr/widgets';
import NotFound from '@actr/widgets/src/components/_misc/not-found';
import React, { FC, lazy, memo, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import WebviewRoutes from 'Router/webview/routes';

const AccountOrdersPage = lazy(() => import('Pages/ak-redesign/main-pages/orders/orders'));
const AccountLoginPage = lazy(() => import('Pages/ak-redesign/auth/login/login'));

const WOWTicketsRouter: FC = () => (
  <Suspense fallback={<SiteLoader />}>
    <Switch>
      <Route exact={true} path={prepareRoutesPaths(AccountRoutes.LOGIN)} component={AccountLoginPage} />
      <Route exact={true} path={prepareRoutesPaths([WebviewRoutes.HOME, '/', WebviewRoutes.ORDERS])} component={AccountOrdersPage} />
      <Route>
        <NotFound disableButtons={true} />
      </Route>
    </Switch>
  </Suspense>
);

export default memo(WOWTicketsRouter);
