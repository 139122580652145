import { HotelRefundStore } from '@actr/stores';
import hotelLog from 'Logs/hotel/base';

export enum HotelOrderRefundLogs {
  CLICK_OPEN_MODAL, // клик по кнопке открытия модалки
  /** @param refundAvailable - возврат доступен */
  OPEN_MODAL, // открытие модалки
  CLOSE_MODAL, // закрытие модалки
  SHOW_NOT_POSSIBLE_INFO, // показ экрана с инфой о невозможности возврата
  /** @param hasFreeRefund - возможен возврат без штрафа */
  SHOW_STATUS_INFO, // показ экрана с инфой о статусе возврата
  SHOW_SUCCESS_INFO, // показ экрана с инфой об успешности возврата
  SHOW_CONFIRM_INFO, // показ экрана с инфой о подтверждении возврата
  /** @param formIsValid - форма валидна */
  CLICK_CONFIRM_BTN, // нажатие на кнопку подтверждения возврата
  TRY_CONFIRM, // отправка запроса на подтверждение возврата
  /** @param pid - */
  CONFIRM_SUCCESS, // запрос на подтверждение возврата завершился успешно
  /** @param pid -
   * @param requestError -
   */
  CONFIRM_FAILED, // запрос на подтверждение возврата завершился ошибкой
}

const hotelRefundLog = hotelLog.extend('refund').bindEnum(HotelOrderRefundLogs);

export function initHotelRefundLogs(store: HotelRefundStore) {
  hotelRefundLog.addData(() => ({
    billingNumber: store.currentOrder?.billing_number,
  }));
}

export default hotelRefundLog;
