import { Store } from '@actr/stores';
import { commonAppLog, initCommonAppLogs } from '@actr/widgets';

const appLog = commonAppLog.extend();

export function initAppLogs(store: Store) {
  initCommonAppLogs(store);
}

export default appLog;
