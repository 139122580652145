import { UseRenderLogging } from '@actr/widgets';
import UseInitLogging from 'Hooks/app/use-init-logging';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode } from 'react';

/** HOC для кода, который должен выполняться после инициализации провайдеров проложения */
const ContentWrapper: FC<{ children: ReactNode }> = props => {
  const { children } = props;

  UseInitLogging();
  UseRenderLogging();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {children}
    </>
  );
};

export default observer(ContentWrapper);
