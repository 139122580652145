import aviaLog from 'Logs/avia/base';

export enum AviaPaymentLogs {
  SURCHARGE_PAY, // начало оплаты
}

const aviaPaymentLog = aviaLog.extend('checkout').bindEnum(AviaPaymentLogs);

export const initAviaPaymentLogs = (): void => {
  aviaPaymentLog.addData(() => ({}));
};

export default aviaPaymentLog;
