import '@actr/widgets/src/logs/web-vitals';

import siteConfig from 'Configs';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import apiSwitcher from 'Root/apiSwitcher';
import App from 'Root/App';

function render() {
  const container = document.getElementById(siteConfig.rootId)!;

  if (container.dataset.isReady === 'true') {
    return;
  }

  container.dataset.isReady = 'true';

  container.classList.add('MainAppContainer');

  if (siteConfig.app.apiSwitcherEnable) {
    apiSwitcher.init();
  }

  hydrateRoot(container, <App />);
}

if (document.readyState !== 'loading') {
  render();
} else {
  document.addEventListener('load', render);
}
