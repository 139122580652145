import { styleIf, styleOnNotLastChild } from '@actr/style';
import { NavLink } from '@actr/widgets';
import styled from 'styled-components';

export const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.surface.base.dark.default,
  position: 'fixed',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '100%',
  padding: `${theme.padding.s} ${theme.padding.xs}`,
  left: 0,
  bottom: 0,
}));

export const Link = styled(NavLink)<{isActive: boolean}>(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: theme.fontSize.x3s,
  ...styleOnNotLastChild({
    marginRight: theme.margin.s,
  }),
  ...styleIf(!isActive, {
    opacity: theme.disableOpacity,
  }),
  '&, &:hover, &:focus ': {
    color: theme.colors.text.inverted.normal.default,
  }
}));

export const IconWrapper = styled.span(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.iconSize.xs,
}));
