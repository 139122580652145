import './styles/style.scss';
import 'core-js/web';
import 'moment/dist/locale/ru';

import mainContainer, { initDI, loadDIModules } from '@actr/di';
import {
  FavoriteRecommendationsService,
  injectGlobalStoreToDi,
  OrdersService,
  Store
} from '@actr/stores';
import authDIModule from '@actr/stores/src/modules/account/auth';
import ordersDIModule from '@actr/stores/src/modules/account/orders';
import accountPassengersEditingDIModule from '@actr/stores/src/modules/account/passengers-editing';
import aviaInsuranceRefundDIModule from '@actr/stores/src/modules/avia/insurance/refund';
import aviaOrderRefundDIModule from '@actr/stores/src/modules/avia/refund';
import aviaAexRefundDIModule from '@actr/stores/src/modules/avia/refund-aex';
import accountUnsuccessfulTicketingDIModule from '@actr/stores/src/modules/avia/unsuccessful-ticketing';
import appDIModule from '@actr/stores/src/modules/common/app';
import hotelRefundDIModule from '@actr/stores/src/modules/hotel/refund';
import railRefundDiModule from '@actr/stores/src/modules/rail/refund';
import { AKThemeProvider, DeviceSizes } from '@actr/style';
import aviakassaThemeMap from '@actr/style/src/themes/aviakassa/map';
import { UiContext } from '@actr/ui';
import {
  ErrorBoundary,
  Footer,
  Header,
  MetricsHoc,
  ScrollToTop,
  SiteLoader,
  WidgetsContext
} from '@actr/widgets';
import { StickyRightButtons } from '@actr/widgets/src/components/_misc/sticky-right-buttons';
import ContentWrapper from 'Components/hoc/content-wrapper';
import config from 'Configs';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { provider, useInstance } from 'react-ioc';
import { Router } from 'react-router';
import SingleOrderRouter from 'Root/router/single-order/index';
import AkRedesignRouter from 'Router/ak-redesign/index';
import WebviewRouter from 'Router/webview';
import WOWTicketsRouter from 'Router/wow-tickets';

import * as Styled from './styles/style';
import ProjectUtils from './utils/project';

momentDurationFormatSetup(moment as any);
moment.locale('ru');

const App: React.FC = provider(
  [Store],
  FavoriteRecommendationsService,
  OrdersService
)(injectGlobalStoreToDi(observer(() => {
  const { app } = useInstance(Store);

  const history = useMemo(() => createBrowserHistory(), []);

  const [isReady, setIsReady] = useState(false);

  // временный костыль, что бы локально тема из ЛС не затирала из конфига
  useEffect(() => {
    app.stopStore();
    app.setTheme(config.theme);
  }, []);

  useEffect(() => {
    setImmediate(() => setIsReady(true));
  }, []);

  const handleUpdateDeviceSize = useCallback((deviceSize: DeviceSizes) => {
    app.setDeviceSize(deviceSize);
  }, []);

  function renderContent() {
    if (ProjectUtils.isWOWTickets()) {
      return (
        <>
          <ScrollToTop />
          <Header isAccount={true} />
          <Styled.Content>
            <WOWTicketsRouter />
          </Styled.Content>
        </>
      );

    }
    if (ProjectUtils.isSber() || ProjectUtils.isRshb()) return <SingleOrderRouter />;
    if (ProjectUtils.isWebview()) return <WebviewRouter />;

    return (
      <>
        <ScrollToTop />
        <Header isAccount={true} />
        <Styled.Content>
          <AkRedesignRouter />
        </Styled.Content>
        <Footer />
        <StickyRightButtons />
      </>
    );
  }

  return (
    <Router history={history}>
      <WidgetsContext.Provider value={{
        isForPartnerWL: config.isForPartnerWL,
        onlyOrdersPage: ProjectUtils.isWOWTickets(),
        excludedPayments: [],
        usePartnerLoaderStyle: ProjectUtils.isPartners()
      }}>
        <UiContext.Provider value={{
          deviceSize: app.deviceSize,
          popoverContainerElement: document.getElementById('popover-container') || document.body,
          modalContainerElement: document.getElementById('modal-container') || document.body,
        }}>
          <AKThemeProvider map={aviakassaThemeMap} theme={app.theme} onUpdateDeviceSize={handleUpdateDeviceSize}>
            <ErrorBoundary>
              <MetricsHoc>
                <ContentWrapper>
                  {isReady ? renderContent() : (
                    <SiteLoader />
                  )}
                </ContentWrapper>
              </MetricsHoc>
            </ErrorBoundary>
          </AKThemeProvider>
        </UiContext.Provider>
      </WidgetsContext.Provider>
    </Router>
  );
}), mainContainer));

export default initDI(loadDIModules(
  App,
  appDIModule,
  authDIModule,
  aviaAexRefundDIModule,
  hotelRefundDIModule,
  aviaInsuranceRefundDIModule,
  aviaOrderRefundDIModule,
  railRefundDiModule,
  ordersDIModule,
  railRefundDiModule,
  accountUnsuccessfulTicketingDIModule, // Нужно для инициализации логов
  accountPassengersEditingDIModule // Нужно для инициализации логов
), mainContainer);
