import config from '@actr/config';
import { IResponseData, post } from '@actr/utilities';

export interface LoginFetchRequestParams {
  email: string;
  billing_number: number;
  signature: string;
}

export function fetchLogin(params: LoginFetchRequestParams): Promise<IResponseData<{auth_token: string}>> {
  return post<{auth_token: string}>({
    url: config.apiV4URL,
    urlPath: 'v4/user/login-by-order',
    data: params
  });
}
