import { injectable } from '@actr/di/src';
import { FieldStore, FormStore, TransformEvents, WithValidation } from '@actr/stores';
import {
  canUseDom,
  isRequired,
  validateEmail
} from '@actr/utilities';
import { action, computed, makeObservable, observable } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';

export type SingleOrderAuthInfoInfo = {
  sig: string;
  billing_number: number;
}

export type SingleOrderLoginForm = FormStore<{
  email: FieldStore<string>;
  billingNumber: FieldStore<string>;
}>

/** Стор формы автаризации сбера */
@injectable()
class SingleOrderLoginStore implements WithValidation {

  readonly form: SingleOrderLoginForm;

  @observable
    ordersAuthInfo: SingleOrderAuthInfoInfo[] = [];

  constructor() {
    this.form = new FormStore({
      email: new FieldStore('', [
        value => isRequired(value) || null,
        value => validateEmail(value) || null,
      ], {
        transform: value => value.replaceAll(' ', ''),
      }),
      billingNumber: new FieldStore('', [
        value => isRequired(value) || null,
      ], {
        transform: {
          [TransformEvents.CHANGE]: value => value.replace(/[^+\d]/g, ''),
        }
      }),
    });

    makeObservable(this);

    makePersistable(this, {
      name: 'single-order-info',
      properties: ['ordersAuthInfo'],
      storage: canUseDom ? window.localStorage : undefined
    });
  }

  // COMPUTES
  @action
  addOrderAuthInfo(orderInfo: SingleOrderAuthInfoInfo) {
    this.ordersAuthInfo.push(orderInfo);
  }

  @computed
  get isValid(): boolean {
    return this.form.isValid;
  }

  @computed
  get isHydrated(): boolean {
    return isHydrated(this);
  }

  @action
  validate(): boolean {
    return this.form.validate();
  }

  @action
  resetFields() {
    this.form.reset();
  }

}

export default SingleOrderLoginStore;
