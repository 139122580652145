import { RailRefundStore } from '@actr/stores';
import railLog from 'Logs/rail/base';

export enum RailOrderRefundLogs {
  CLICK_OPEN_MODAL, // клик по кнопке открытия модалки
  OPEN_MODAL, // открытие модалки
  CLOSE_MODAL, // закрытие модалки
  TRY_GET_TICKET_INFO, // отправка запроса на получение билетов
  /** @param pid */
  GET_TICKET_INFO_SUCCESS, // запрос на получение билетов успешен
  /** @param pid */
  GET_TICKET_INFO_FAILED, // запрос на получение билетов завершился ошибкой

  TRY_GET_AMOUNTS, // отправка запроса на сумм
  /** @param pid */
  GET_AMOUNTS_SUCCESS, // запрос на получение сумм успешен
  /** @param pid */
  GET_AMOUNTS_FAILED, // запрос на получение сумм завершился ошибкой

  /** @param refundAvailable - возврат разрешен */
  ALL_REFUND_INFO_REQUEST_COMPLETED, // все запросы для начала возврата завершены

  TRY_REFUND_TICKET, // запрос на возврат билетов
  /** @param pid */
  REFUND_TICKET_SUCCESS, // запрос на возврат билетов успешен
  /** @param pid */
  REFUND_TICKET_FAILED, //  запрос на возврат билетов завершился ошибкой
  /** @param pid */
  REFUND_TICKET_PARTIALLY_SUCCESS, //  запрос на возврат билетов частично успешен

  SHOW_NOT_POSSIBLE_INFO, // показ экрана с инфой о невозможности возврата
  SHOW_SUCCESS_INFO, // показ экрана с инфой об успешности возврата
  SHOW_CONFIRM_INFO, // показ экрана с подтверждением возврата
  /** @param successPid - пиды успешных запросов
   *  @param errorsPid - пиды успешных запросов c ошибкой
   */
  SHOW_PARTIALLY_SUCCESS_INFO, // показ экрана с инфой о частичном успехе возврата
  /** @param allowMakeRefund - разрешено ли делать возврат
   *  @param mustRefundBackSegmentFirst - сначала нужно вернуть сегмент ОБРАТНО
   *  @param segmentsAvailableToRefund - количество сегментов, для которых доступен возврат
   */
  SHOW_SELECTION_INFO, // показ экрана с выбором билетов к возврату
  CLICK_TO_CONFIRM_BTN, // нажата кнопка перехода к подтверждению
  CLICK_CONFIRM_BTN, // нажата кнопка подтверждения

  /** @param index - индекс сегмента, начиная с 0
   * @param refundAvailable - возврат разрешен
   */
  SELECTION_INFO_SELECT_SEGMENT, // выбран сегмент для просмотра
}

const railRefundLog = railLog.extend('refund').bindEnum(RailOrderRefundLogs);

export function initRailRefundLogs(store: RailRefundStore) {
  railRefundLog.addData(() => ({
    billingNumber: store.order?.billing_number,
  }));
}

export default railRefundLog;
