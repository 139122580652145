import { prepareRoutesPaths } from '@actr/utilities';
import { SiteLoader } from '@actr/widgets';
import PageWrapper from 'Pages/webview/_common/page-wrapper';
import React, { FC, lazy, memo, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import WebviewRoutes from 'Router/webview/routes';

const AccountOrdersPage = lazy(() => import('Pages/ak-redesign/main-pages/orders/orders'));
const AccountPassengersPage = lazy(() => import('Pages/ak-redesign/main-pages/passengers-notebook/passengers-notebook'));
const AccountProfile = lazy(() => import('Pages/ak-redesign/main-pages/profile/profile'));
const AccountEmailChangePage = lazy(() => import('Pages/ak-redesign/auth/email-change/email-change'));

const WebviewRouter: FC = () => (
  <PageWrapper>
    <Suspense fallback={<SiteLoader />}>
      <Switch>
        <Route exact={true} path={prepareRoutesPaths([WebviewRoutes.HOME, '/', WebviewRoutes.ORDERS])} component={AccountOrdersPage} />
        <Route path={prepareRoutesPaths(WebviewRoutes.PASSENGERS)} component={AccountPassengersPage} />
        <Route path={prepareRoutesPaths(WebviewRoutes.PROFILE)} component={AccountProfile} />
        {/* email change */}
        <Route path={prepareRoutesPaths(WebviewRoutes.EMAIL_CHANGE)} component={AccountEmailChangePage} />
        <Route>
          <SiteLoader />
        </Route>
      </Switch>
    </Suspense>
  </PageWrapper>

);

export default memo(WebviewRouter);
