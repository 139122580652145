import { Locales } from '@actr/specs';
import { createTranslatorHook } from '@actr/translator';

import en_US from './en_US.json';
import ru_RU from './ru_RU.json';

const [useCommonTranslator, commonTranslator] = createTranslatorHook({
  namespace: 'common',
  locales: {
    [Locales.en_US]: en_US,
    [Locales.ru_RU]: ru_RU
  }
});

export default useCommonTranslator;

export {
  commonTranslator
};
