import merge from 'lodash/merge';

import baseConfig from './base';
import { LocalConfig } from './specs';

const devprodConfig = (): LocalConfig => merge(baseConfig, {
  aviakassaV2URL: 'https://v2.aviakassa.com',
  apiV4URL: 'https://api-v2.aviakassa.com/',
  cabinetURL: 'https://www.aviakassa.com/account/',
  cmsAuthToken: 'NF0hK7YLPWydigAfJ',

  metricsTracking: {
    googleAnalytics: false,
    yandexMetrics: true,
  },

  YM_TrackingID: '97105727',

  lkRedesign: {
    isUseOldAviakassa: true,
    aviakassaURL: 'https://www.aviakassa.com'
  },

  app: {
    trackingEnable: true,
    aprtxEnable: true,
    intentMediaEnable: true,
    GTMEnable: true,
  },

  avia: {
    /** возможно возврата заказа для АВИА */
    orderRefund: {
      /** разрешен ли добровольный возврат */
      allowVoluntaryRefund: true,
      /** разрешен ли вынужденный возврат */
      allowForcedRefund: true,
      /** включает возможность выбора способа возврата средств (на новую карту или по реквизитам) */
      enableRefundMethodSelection: false,
    },
    orderExchange: {
      /** разрешен ли добровольный обмен */
      allowVoluntaryExchange: true,
      /** разрешен ли вынужденный обмен */
      allowForcedExchange: true,
    },
    /** конфиг неуспешек */
    unsuccessfulTicketingRefund: {
      /** включить неуспсешки */
      enableRefund: true
    },
    /** Включить возврат Аэроэкспресса */
    enableAexRefund: true,
    /** Включить возврат страховок */
    enableInsuranceRefund: true,
  },

  rail: {
    /** включение возможности возврата заказа */
    enableOrderRefund: true,
    /** включение возможности изменение электронной регистрации */
    enableOrderErChange: true
  },

  hotel: {
    /** включение возможности возврата заказа */
    enableOrderRefund: true
  },
  addProvisionalHeaders: true,

  headers: {
    'Client-Host': 'v2.aviakassa.com',
  }
});

export default devprodConfig;
