import config from '@actr/config';
import { ThemeName } from '@actr/specs';
import merge from 'lodash/merge';

import ProjectTypes from '../specs/project/types';
import { LocalConfig } from './specs';

const baseConfig: LocalConfig = merge(config, {
  theme: ThemeName.AVIAKASSA_ROUNDED,
  projectType: ProjectTypes.AK_REDESIGN,

  YM_TrackingID: '97105794',
  rootId: 'aviakassa-v2-account-app',
  project: 'FrontAkAccountV2',
  isForPartnerWL: false,
  account: {
    searchOrders: {
      enableGrouping: true
    }
  },

  avia: {
    enablePostSale: true,
    enablePassengersEditing: false,
  },

  feedbackFormParams: {
    isEnabled: true,
    id: '272',
    partner: 'SBER',
    url: 'https://s2-actr-partner-site-actr-front.crpo.su/feedback.js',
    channelToken: '734573dce0817696376093ddd29e52ee84c63e13',
  }
});

export default baseConfig;
