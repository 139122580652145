import { useInject } from '@actr/di/src';
import { useNavigation } from '@actr/hooks';
import { AuthService, AuthStore } from '@actr/stores';
import { Sizes } from '@actr/style';
import { Alert, AlertTypes, Container, Space } from '@actr/ui';
import { SiteLoader } from '@actr/widgets';
import { hydrateStore } from 'mobx-persist-store';
import { observer } from 'mobx-react-lite';
import ToolBar from 'Pages/webview/_common/page-wrapper/tool-bar';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import useCommonTranslator from 'Root/locales';

import * as Styled from './style';

export type PageWrapperProps = {
    children: ReactNode;
}
const PageWrapper: FC<PageWrapperProps> = props => {
  const { children } = props;

  const auth = useInject(AuthStore);
  const authService = useInject(AuthService);

  const { t } = useCommonTranslator();

  const { searchParams } = useNavigation();
  const { auth_token } = searchParams;
  const [isReadyToShowContent, setIsReadyToShowContent] = useState(false);

  const hasAuthToken = !!auth_token;

  useEffect(() => {
    function onStoreReady() {
      if (auth_token) {
        auth.setToken(auth_token || null);
      }

      if (auth.token && !auth.isAuthorized) {
        authService.getMe()
          .finally(() => {
            setIsReadyToShowContent(true);
          });
      } else {
        setIsReadyToShowContent(true);
      }
    }

    if (!auth.isHydrated) {
      hydrateStore(auth).then(onStoreReady);
    } else {
      onStoreReady();
    }
  }, []);

  if (!isReadyToShowContent) {
    return <SiteLoader />;
  }

  if (!hasAuthToken && !auth.isAuthorized) {
    return (
      <Container>
        <Space size={Sizes.L} />
        <Alert type={AlertTypes.DANGER}>
          {t('Нет токена авторизации')}
        </Alert>
      </Container>
    );
  }

  return (
    <Styled.Wrapper>
      {children}
      <ToolBar />
    </Styled.Wrapper>
  );
};

export default observer(PageWrapper);
