import * as UI from '@actr/ui';
import { prepareRoutesPaths } from '@actr/utilities';
import { AccountRoutes } from '@actr/widgets';
import NotFound from '@actr/widgets/src/components/_misc/not-found';
import React, { FC, lazy, memo, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Styled from './style';

const AccountOrdersPage = lazy(() => import('Pages/ak-redesign/main-pages/orders/orders'));
const AccountPassengersPage = lazy(() => import('Pages/ak-redesign/main-pages/passengers-notebook/passengers-notebook'));
const AccountProfile = lazy(() => import('Pages/ak-redesign/main-pages/profile/profile'));
const AccountPartnerPage = lazy(() => import('Pages/ak-redesign/main-pages/partner/partner'));
const AccountRegisterPage = lazy(() => import('Pages/ak-redesign/auth/register/register'));
const AccountPasswordPage = lazy(() => import('Pages/ak-redesign/auth/password/password'));
const AccountRegisterConfirmPage = lazy(() => import('Pages/ak-redesign/auth/register-confirm/register-confirm'));
const AccountRestorePasswordPage = lazy(() => import('Pages/ak-redesign/auth/password-restore/password-restore'));
const AccountEmailChangePage = lazy(() => import('Pages/ak-redesign/auth/email-change/email-change'));
const AccountLoginPage = lazy(() => import('Pages/ak-redesign/auth/login/login'));
const AccountLoginSessionPage = lazy(() => import('Pages/ak-redesign/auth/login-session/login-session'));

const AkRedesignRouter: FC = () => (
  <Suspense fallback={<Styled.Loader><UI.Spinner /></Styled.Loader>}>
    <Switch>
      {/* Orders */}
      <Route exact={true} path={prepareRoutesPaths([AccountRoutes.HOME, '/', AccountRoutes.ORDERS])} component={AccountOrdersPage} />
      {/* Login */}
      <Route exact={true} path={prepareRoutesPaths(AccountRoutes.LOGIN_FROM_SESSION)} component={AccountLoginSessionPage} />
      <Route exact={true} path={prepareRoutesPaths(AccountRoutes.LOGIN)} component={AccountLoginPage} />
      {/* register */}
      <Route exact={true} path={prepareRoutesPaths(AccountRoutes.REGISTER)} component={AccountRegisterPage} />
      {/* register */}
      <Route exact={true} path={prepareRoutesPaths(AccountRoutes.PASSWORD)} component={AccountPasswordPage} />
      {/* register confirm */}
      <Route path={prepareRoutesPaths(AccountRoutes.REGISTER_CONFIRM)} component={AccountRegisterConfirmPage} />
      {/* password restore */}
      <Route path={prepareRoutesPaths(AccountRoutes.PASSWORD_RESTORE)} component={AccountRestorePasswordPage} />
      {/* email change */}
      <Route path={prepareRoutesPaths(AccountRoutes.EMAIL_CHANGE)} component={AccountEmailChangePage} />
      {/* Passengers */}
      <Route path={prepareRoutesPaths(AccountRoutes.PASSENGERS)} component={AccountPassengersPage} />
      {/* Profile */}
      <Route path={prepareRoutesPaths(AccountRoutes.PROFILE)} component={AccountProfile} />
      {/* Partner */}
      <Route path={prepareRoutesPaths(AccountRoutes.PARTNER)} component={AccountPartnerPage} />
      {/* Not Found */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  </Suspense>

);

export default memo(AkRedesignRouter);
