import { inject, injectable } from '@actr/di';
import { AuthStore } from '@actr/stores';
import { runInAction } from 'mobx';
import { fetchLogin } from 'Services/requests/single-order';
import SingleOrderLoginStore from 'Store/single-order/login';

@injectable()
export class SingleOrderAuthService {

  @inject(SingleOrderLoginStore)
    singleOrderLoginStore!: SingleOrderLoginStore;

  @inject(AuthStore)
    auth!: AuthStore;

  constructor() {
    this.logIn = this.logIn.bind(this);
  }

  /** Авторизация пользователя */
  async logIn(signature: string): Promise<null> {
    const { email, billingNumber } = this.singleOrderLoginStore.form.fields;

    const action = this.auth.getServerAction('logIn');
    action.pending();

    try {
      const response = await fetchLogin({
        email: email.value,
        billing_number: Number(billingNumber.value),
        signature,
      });

      runInAction(() => {
        this.auth.setToken(response.data.auth_token);
        this.singleOrderLoginStore.addOrderAuthInfo({
          billing_number: Number(billingNumber.value),
          sig: signature,
        });
        action.complete(response.pid);
      });

      return null;
    } catch (err: any) {
      const errorMessage = err.data.block_time ? 'Пользователь заблокирован' : err.message;
      action.error(errorMessage, err.pid);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        ...err as Error,
        userIsBlocked: !!err.data.block_time,
      } as unknown);
    }
  }

}
