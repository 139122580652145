import { loadDIModules } from '@actr/di/src';
import { Space } from '@actr/ui';
import { prepareRoutesPaths } from '@actr/utilities';
import { SiteLoader } from '@actr/widgets';
import NotFound from '@actr/widgets/src/components/_misc/not-found';
import singleOrderDIModule from 'Modules/single-order';
import React, { FC, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import SingleOrderRoutes from './routes';

const LoginPage = lazy(() => import('Pages/single-order/login/login'));
const OrderPage = lazy(() => import('Pages/single-order/avia/order/order'));

const SingleRouter: FC = () => (
  <Suspense fallback={<SiteLoader />}>
    <Space />
    <Switch>
      <Route exact={true} path={prepareRoutesPaths(SingleOrderRoutes.LOGIN)} component={LoginPage} />
      <Route exact={true} path={prepareRoutesPaths(SingleOrderRoutes.ORDER)} component={OrderPage} />
      {/* Not Found */}
      <Route>
        <NotFound disableButtons={true} />
      </Route>
    </Switch>
  </Suspense>
);

export default loadDIModules(SingleRouter, singleOrderDIModule);
