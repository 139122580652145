enum WebviewRoutes {
    HOME = '/account',
    PROFILE = '/account/profile/',
    AVIA_ORDER = '/order-payment/',
    PASSENGERS = '/account/passengers',
    AVIA_SEARCH = '/avia/search',
    ORDERS = '/account/orders/?sig'
        + 'aviaExchangeOrderBookingNumber'
        + '&aviaRefundOrderBookingNumber'
        + '&billingNumber'
        + '&aviaRefundAexOrderBookingNumber'
        + '&aviaRefundInsuranceOrderBookingNumber',
    EMAIL_CHANGE = '/account/email/change/?new_email&token',
}

export default WebviewRoutes;
