enum SingleOrderRoutes {
    LOGIN = '/account/login/:sig',

    ORDER = '/account/orders/?sig'
    + 'aviaExchangeOrderBookingNumber'
    + '&aviaRefundOrderBookingNumber'
    + '&billingNumber'
    + '&aviaRefundAexOrderBookingNumber'
    + '&aviaRefundInsuranceOrderBookingNumber',
  }

export default SingleOrderRoutes;
