import config from '../configs';
import ProjectTypes from '../specs/project/types';

class ProjectUtils {

  static isSber() {
    return config.projectType === ProjectTypes.SBER;
  }

  static isRshb() {
    return config.projectType === ProjectTypes.RSHB;
  }

  static isAkRedesign() {
    return config.projectType === ProjectTypes.AK_REDESIGN;
  }

  static isWebview() {
    return config.projectType === ProjectTypes.WEBVIEW;
  }

  static isWOWTickets() {
    return config.projectType === ProjectTypes.WOW_TICKETS;
  }

  static isPartners() {
    return [ProjectTypes.SBER, ProjectTypes.RSHB, ProjectTypes.WOW_TICKETS].includes(config.projectType);
  }

}

export default ProjectUtils;
