import { set as setConfig } from '@actr/config';
import config from 'Configs';
import flattenDeep from 'lodash/flattenDeep';
import isArray from 'lodash/isArray';
import qs from 'qs';

const container: HTMLDivElement = document.createElement('div');
const select: HTMLSelectElement = document.createElement('select');
const input: HTMLInputElement = document.createElement('input');
const button: HTMLButtonElement = document.createElement('button');
const toggleButton: HTMLButtonElement = document.createElement('button');

export default {

  containerShow: false,

  init(): void {
    const params = this.getQueryParams();
    if (params.apiv4_url) {
      const apiUrl = isArray(params.apiv4_url) ? params.apiv4_url : params.apiv4_url.split(',');
      setConfig({ apiV4URL: isArray(apiUrl) ? flattenDeep(apiUrl)[0] : apiUrl });
    }
    this.renderSwitcher();
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getQueryParams(): any {
    return qs.parse(window.location.href.split('?')[1] || '');
  },

  getList(): string[] {
    return [
      'https://api4dev.aviakassa.ru/',
      'https://api4test.aviakassa.ru/',
      'https://api4pre.aviakassa.ru/',
      'https://api4.aviakassa.com/',
      'http://akl.lo/',
      'http://s1.akl.ak.localenv/',
      'http://s0.akl.ak.deac/',
      'http://s1.akl.ak.deac/',
      'http://s2.akl.ak.deac/',
      'http://s3.akl.ak.deac/',
      'http://s4.akl.ak.deac/',
      'http://s5.akl.ak.deac/',
      'http://s6.akl.ak.deac/',
      'http://s7.akl.ak.deac/',
      'http://s8.akl.ak.deac/',
      'http://s9.akl.ak.deac/',
      'http://s10.akl.ak.deac/',
      'http://s11.akl.ak.deac/',
      'http://s12.akl.ak.deac/',
      'http://s13.akl.ak.deac/',
      'http://s14.akl.ak.deac/',
      'http://s15.akl.ak.deac/',
      'http://s16.akl.ak.deac/',
      'http://s17.akl.ak.deac/',
      'http://s18.akl.ak.deac/',
      'http://s19.akl.ak.deac/',
      'http://s20.akl.ak.deac/',
      'http://s21.akl.ak.deac/',
      'http://s22.akl.ak.deac/',
      'http://s23.akl.ak.deac/',
      'http://s24.akl.ak.deac/',
      'http://s25.akl.ak.deac/',
      'http://s26.akl.ak.deac/',
      'http://s27.akl.ak.deac/',
      'http://s28.akl.ak.deac/',
      'http://s29.akl.ak.deac/',
      'http://s30.akl.ak.deac/',
      'http://s31.akl.ak.deac/',
      'http://s32.akl.ak.deac/',
      'https://s0-akl-ak.crpo.su/',
      'https://s1-akl-ak.crpo.su/',
      'https://s2-akl-ak.crpo.su/',
      'https://s3-akl-ak.crpo.su/',
      'https://s4-akl-ak.crpo.su/',
      'https://s5-akl-ak.crpo.su/',
      'https://s6-akl-ak.crpo.su/',
      'https://s7-akl-ak.crpo.su/',
      'https://s8-akl-ak.crpo.su/',
      'https://s9-akl-ak.crpo.su/',
      'https://s10-akl-ak.crpo.su/',
      'https://s11-akl-ak.crpo.su/',
      'https://s12-akl-ak.crpo.su/',
      'https://s13-akl-ak.crpo.su/',
      'https://s14-akl-ak.crpo.su/',
      'https://s15-akl-ak.crpo.su/',
      'https://s16-akl-ak.crpo.su/',
      'https://s17-akl-ak.crpo.su/',
      'https://s18-akl-ak.crpo.su/',
      'https://s19-akl-ak.crpo.su/',
      'https://s20-akl-ak.crpo.su/',
      'https://s21-akl-ak.crpo.su/',
      'https://s22-akl-ak.crpo.su/',
      'https://s23-akl-ak.crpo.su/',
      'https://s24-akl-ak.crpo.su/',
      'https://s25-akl-ak.crpo.su/',
      'https://s26-akl-ak.crpo.su/',
      'https://s27-akl-ak.crpo.su/',
      'https://s28-akl-ak.crpo.su/',
      'https://s29-akl-ak.crpo.su/',
      'https://s30-akl-ak.crpo.su/',
      'https://s31-akl-ak.crpo.su/',
      'https://s32-akl-ak.crpo.su/',
      'http://s1.fake-api.ms.deac/',
      'https://s1.fake-api.ms.crpo.su/',
      'Custom',
    ];
  },

  isCustomURL(): boolean {
    return this.getList().indexOf(config.apiV4URL) < 0;
  },

  toggleInput(): void {
    const display = select.value === 'Custom' ? '' : 'none';
    input.style.display = display;
  },

  changeURL(url: string): void {
    setConfig({ apiV4URL: url });
    const params = this.getQueryParams();
    params.apiv4_url = url;
    window.location.href = `${window.location.href.split('?')[0]}?${qs.stringify(params)}`;
  },

  handleSelectChange(): void {
    this.toggleInput();
    if (select.value !== 'Custom') {
      this.changeURL(select.value);
    }
  },

  handleButtonClick(): void {
    if (select.value === 'Custom') {
      this.changeURL(input.value);
    } else {
      this.changeURL(select.value);
    }
  },

  handleToggleButtonClick(): void {
    if (!this.containerShow) {
      toggleButton.innerHTML = 'API <';
      container.style.display = 'block';
      this.containerShow = true;
    } else {
      toggleButton.innerHTML = 'API >';
      container.style.display = 'none';
      this.containerShow = false;
    }
  },

  renderSwitcher(): void {
    container.id = 'apiSwitcherContainer';
    container.style.cssText = 'display: none;'
    + 'position: fixed; bottom: 0; left: 0; right: 100px; text-align: center;'
    + 'background: #bbb; padding: 5px; z-index: 9999; opacity: 0.9;';
    this.containerShow = false;

    select.style.cssText = 'margin-right: 5px;';
    select.onchange = (): void => this.handleSelectChange();

    toggleButton.style.cssText = 'cursor: pointer; height: 34px;'
      + 'position: fixed; bottom: 0; width: 95px; left: 0; text-align: center;'
      + 'background: #bbb; padding: 5px; z-index: 9999; opacity: 0.9;';

    toggleButton.innerHTML = 'API >';
    toggleButton.onclick = (): void => this.handleToggleButtonClick();

    input.style.cssText = 'margin-right: 5px;';
    input.setAttribute('placeholder', 'http://localhost:80/');
    if (this.isCustomURL()) {
      input.value = config.apiV4URL;
    }

    button.innerHTML = 'Apply';
    button.onclick = (): void => this.handleButtonClick();

    const list = this.getList();
    list.forEach(item => {
      const option = document.createElement('option');
      option.innerHTML = item;
      option.setAttribute('value', item);
      if (this.isCustomURL()) {
        if (item === 'Custom') {
          option.setAttribute('selected', 'selected');
        }
      } else if (item === config.apiV4URL) {
        option.setAttribute('selected', 'selected');
      }
      select.appendChild(option);
    });

    container.appendChild(select);
    container.appendChild(input);
    container.appendChild(button);
    document.body.appendChild(container);
    document.body.appendChild(toggleButton);

    this.toggleInput();
  }

};
